import React from "react";
import "./styles.css";
// import logo from "../../../assets/images/Logo.png";
import logo from "../../../assets/images/logo-new.jpg";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

const Footer = () => {
  return (
    <section>
      <TawkMessengerReact
        propertyId="65a645450ff6374032c0cb17"
        widgetId="1hk8nt62u"
      />
      <footer className="footer">
        <div class="footer-center col-md-4 col-sm-6">
          <div>
            <i class="fa-solid fa-location-dot"></i>
            <p>
              <span>
              NFSSC-National Fire and Safety Service College, Behind Globus ITI, 
              Ajni BO Bai Kanhan Pipri, Nagpur, Maharashtra.
              </span>
              Pin Code - 441401
            </p>
          </div>
          <div>
            {/* <i class="fa fa-phone"></i> */}
            <p className="text-center">
              {" "}
              (+91) 9827723101 <br />
              (+91) 9420946101
            </p>
          </div>
        </div>

        <div className="footer-left col-md-4 col-sm-6">
          <div>
            <i class="fa-solid fa-location-dot"></i>
            <p>
              <span>
                NFSSC Campus No. 2
                S.H – 19, Gaygohan,Gangiwara, Umreth
              </span>
              Pachmarhi Road, Chhindwara – 480441 (MP)
            </p>
          </div>
          <div>
            {/* <i class="fa fa-phone"></i> */}
            <p className="text-center">
              {" "}
              (+91) 9420946101 <br /> (+91) 9827723101
            </p>
          </div>
        </div>

        <div className="footer-right col-md-4 col-sm-6">
          <div className="map-box">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3719.811462138766!2d79.20572637526134!3d21.1996473804928!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjHCsDExJzU4LjciTiA3OcKwMTInMjkuOSJF!5e0!3m2!1sen!2sin!4v1711169616921!5m2!1sen!2sin"
              allowfullscreen=""
            ></iframe>
          </div>

          <div className="icons text-center">
            <a href="https://www.facebook.com/profile.php?id=100089319158827&mibextid=ZbWKwL">
              <i className="fa-brands fa-facebook" />
            </a>
            <a href="https://twitter.com/CommuGrow?t=L1-YBEpfxEFartYOu9vJAw&s=09">
              <i className="fa-brands fa-twitter" />
            </a>
            <a href="https://youtube.com/@commugrowofficial">
              {" "}
              <i className="fa-brands fa-youtube" />
            </a>
            <a href="https://www.linkedin.com/company/commugrow-official/">
              <i className="fa-brands fa-linkedin-in" />
            </a>
            <a href="https://www.instagram.com/invites/contact/?i=pl6j8gzvaus5&utm_content=q7hyxth">
              <i className="fa-brands fa-instagram" />
            </a>
            <a href="#">
              <i class="fa fa-instagram"></i>
            </a>
          </div>
        </div>
      </footer>
    </section>
  );
};
export default Footer;
